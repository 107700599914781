import * as React from 'react';
import _ from "lodash";
import { Avatar, Fade} from '@material-ui/core';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import "../bottomStyle.css";
import {useSelector, useDispatch} from "react-redux";
import { getImage } from '../../initialize/service/navigation';

// 
export default function MenuAppBar({ onChangePath }) {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [clubs, setClubs] = React.useState({});

  const authData = useSelector((state)=>state.auth.get('authData'));
  const allClubs = useSelector((state)=>state.home.get('allClubs'));
  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(()=>{
    const obj = _.groupBy(allClubs, 'id');
    setClubs(obj);
  },[allClubs])




  return (
    <Box className={"topNavStyle"} sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        <Typography style={{ textAlign: "center" }} className={'topoHeadText'} gutterBottom variant="h6" component="h2">
          {_.get(clubs,`[${_.get(authData,'clubid','')}][0].company`)}
          </Typography>
          <Typography style={{ textAlign: "center", color:'#ffffff' }} variant="body2" color="textSecondary" component="p">
          {_.get(authData,'first_name','')} {_.get(authData,'last_name','')}
          </Typography>
          </Typography>
     
          {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar alt={`${_.get(authData,'first_name','')} ${_.get(authData,'last_name','')}`} src={getImage(_.get(authData,'pimage',''))}  />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={() => { onChangePath('/login') }}>Sign Out</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
