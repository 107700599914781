import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContentText, TextField, DialogActions, Button, DialogContent, Grid, Select, MenuItem, InputLabel, OutlinedInput, Menu, Paper } from "@material-ui/core";
import SelectInput from "../Input/SelectInput";
import { CapturePhoto, PhotoUploadModal } from "../";
import { useSelector } from "react-redux";
import _ from "lodash";
import fs from "fs"

// import AdapterDateFns from '@mui/lab/AdapterDayjs';
//  import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import TimePicker from '@mui/lab/TimePicker';
// import DateTimePicker from '@mui/lab/DateTimePicker';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
//  import MobileDatePicker from '@mui/lab/MobileDatePicker';


const IMG_TYPE = {
    PROFILE: "PROFILE",
    ID_ONE: 'ID_ONE',
    ID_TWO: 'ID_TWO',
};



export default ({ open = false, handleClose = () => { }, onAddUser = () => { } }) => {
    const [email, setEmail] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [imageType, setImageType] = useState(IMG_TYPE.PROFILE);
    const [isUpload, setIsUpload] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [isTakePicOpen, setTakePicOpen] = useState(false);
    const [isUploadPicOpen, setUploadPicOpen] = useState(false);
    const [profileImage, setProfileImage] = useState('');
    const [profileImageName, setProfileImageName] = useState('');
    const [idImage, setIdImage] = useState('');
    const [idImageName, setIdImageName] = useState('');
    const [isEmail, setIsEmailValid] = useState(false);
    const [isFNameValid, setIsFNameValid] = useState(false);
    const [isLNameValid, setIsLNameValid] = useState(false);


    const [isEmailOk, setIsEmailValidOk] = useState(true);
    const [isFNameValidOk, setIsFNameValidOk] = useState(true);
    const [isLNameValidOk, setIsLNameValidOk] = useState(true);

    // const [password, setPassword] = useState('');
    // const [isPasswordValidOk, setIsPasswordValidOk] = useState(true);
    // const [isPasswordValid, setIsPasswordValid] = useState(false);

    const [bdate, setBdate] = useState(new Date(''));
    const [isBDateValidOk, setIsBDataeValidOk] = useState(true);
    const [isBDataeValid, setIsBDataeValid] = useState(false);


    const authData = useSelector(state => state.auth.get('authData'));
    console.log(authData)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        emptyField()
    }, [])

    const emptyField = () => {
        setEmail('');
        // setPassword('');
        setFirstName('');
        setLastName('');
        setProfileImage('');
        setProfileImageName('');
        setIdImage('');
        setIdImageName('');
        setIsEmailValid(false);
        setIsFNameValid(false);
        setIsLNameValid(false);
        setIsBDataeValidOk(true)
        setIsBDataeValid(false);
        setBdate('');
        // setIsPasswordValid(false);
    }

    const changeEmail = (event) => {
        const text = event.target.value;

        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/;
        let isValid = false;

        if (text.trim().length > 3) {
            isValid = reg.test(text.trim());
        }
        setEmail(event.target.value);
        setIsEmailValid(isValid);
        setIsEmailValidOk(true)
    };

    const changeBDate = (event) => {
        const text = event.target.value;
        const isValid = text.trim().length > 2;
        setBdate(event.target.value);
        setIsBDataeValid(isValid);
        setIsBDataeValidOk(true)
    };

    // const changePassword = (event) => {
    //     const text = event.target.value;
    //     const isValid = text.trim().length > 5;
    //     setPassword(event.target.value);
    //     setIsPasswordValid(isValid);
    //     setIsPasswordValidOk(true)
    // };

    const changeFirstName = (event) => {
        const text = event.target.value;
        const isValid = text.trim().length > 3;
        setFirstName(event.target.value);
        setIsFNameValid(isValid);
        setIsFNameValidOk(true)
    };

    const changeLastName = (event) => {
        const text = event.target.value;
        const isValid = text.trim().length > 3;
        setLastName(event.target.value);
        setIsLNameValid(isValid);
        setIsLNameValidOk(true);
    };

    const onUpload = () => {
        setIsUpload(true);
        handleCloseMenu();
        setUploadPicOpen(true);
    }

    const onCapture = () => {
        setIsUpload(false);
        handleCloseMenu();
        setTakePicOpen(true)
    }

    const onOpenMenu = (event, type) => {
        setImageType(type)
        handleClick(event);
    }

    const onApplyTakePic = async (data) => {

        const files = await b64toBlob(data)
        setTakePicOpen(false);
        if (imageType === IMG_TYPE.PROFILE) {
            setProfileImageName(data);
            setProfileImage({ files });
        }
        if (imageType === IMG_TYPE.ID_ONE) {

            setIdImage({ files });
            setIdImageName(data)
        }
    }
    const onApplyUploadPic = (data) => {

        setUploadPicOpen(false);
        if (imageType === IMG_TYPE.PROFILE) {
            setProfileImageName(data.name);
            setProfileImage(data);
        }
        if (imageType === IMG_TYPE.ID_ONE) {
            setIdImage(data);
            setIdImageName(data.name);
        }
    }

    const b64toBlob = (url, filename = `${new Date().getTime()}.jpg`, mimeType = 'image/jpeg') => {
        mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    // //Usage example:
    // urltoFile('data:image/png;base64,......', 'a.png')
    // .then(function(file){
    //     console.log(file);
    // })

    const onClickButton = () => {

        if (isEmail && isFNameValid && isLNameValid && isBDataeValid && profileImageName.length > 2 && idImageName.length > 2) {
            const formData = new FormData();
            formData.append('profile_img', profileImage.files, `p${new Date().getTime()}.jpg`);
            formData.append('signature_img', idImage.files, `s${new Date().getTime()}.jpg`);
            formData.append('first_name', first_name);
            formData.append('last_name', last_name);
            formData.append('email', email);
            formData.append('bdate', bdate);
            formData.append('clubid', _.get(authData, 'clubid'));
            //formData.append('club_id', "KDK");

             onAddUser(formData)
            // onAddUser({ first_name, last_name, email, password });
            emptyField()
        } else {


            if (!isLNameValid) {
                setIsLNameValidOk(false)
            }

            if (!isFNameValid) {
                setIsFNameValidOk(false)
            }
            if (!isBDataeValid) {
                setIsBDataeValidOk(false)
            }

            if (!isEmail) {
                setIsEmailValidOk(false)
            }
            if (profileImageName.length <= 0) {
                alert("Please upload profile image")
            }
            if (idImageName.length <= 0) {
                alert("Please upload profile image")
            }
            else if (!(isEmail && isFNameValid && isLNameValid && isBDataeValid)) {
                alert("Please fill form before submit")

            }
        }


    }

    return (
        <>
            <Dialog open={open}
            // onClose={handleClose}
            >
                <DialogTitle>Add new player</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        Add new player to your team
                    </DialogContentText> */}
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} >
                            <TextField
                                autoFocus
                                margin="dense"
                                id="outlined-name"
                                error={!isEmailOk}
                                // id="name"
                                label="Email"
                                type="text"
                                fullWidth
                                helperText={isEmailOk ? "" : "Email is invalid, please add valid email"}
                                value={email}
                                onChange={changeEmail}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                            margin="dense"
                                id="date"
                                label="Birthday"
                                type="date"
                                fullWidth
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={bdate}
                                onChange={changeBDate}
                               helperText={isBDateValidOk?'':'Please add date of birth'}
                               error={!isBDateValidOk}
                            />
                            {/* <TextField
                                autoFocus
                                error={!isPasswordValidOk}
                                margin="dense"
                                id="outlined-name"
                                // id="name"
                                label="Password"
                                type="text"
                                fullWidth
                                helperText={isPasswordValidOk ? "" : "Password is invalid, please add minimum 5 character"}
                                value={password}
                                onChange={changePassword}
                            /> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <TextField
                                autoFocus
                                margin="dense"
                                id="outlined-name"
                                error={!isFNameValidOk}
                                // id="name"
                                label="First Name"
                                type="text"
                                fullWidth
                                helperText={isFNameValidOk ? "" : "First Name is invalid, first name should be minimum 3 character"}
                                value={first_name}
                                onChange={changeFirstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="outlined-name"
                                error={!isLNameValidOk}
                                // id="name"
                                label="Last Name"
                                type="text"
                                fullWidth
                                helperText={isLNameValidOk ? "" : "Last Name is invalid, last name should be minimum 3 character"}
                                value={last_name}
                                onChange={changeLastName}
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={4} >
                            <Button
                                id="add-btn"
                                aria-controls="add-menu"
                                aria-haspopup="true"
                                aria-expanded={openMenu ? 'true' : undefined}
                                onClick={(event) => {
                                    onOpenMenu(event, IMG_TYPE.PROFILE)
                                }}
                                style={{ backgroundColor: '#4aedc4', color: '#000000' }} variant={'contained'} >Profile Picture</Button>
                        </Grid>


                        <Grid item xs={6} sm={8} >
                            <Paper style={{ overflow: "scroll" }}>{profileImageName}</Paper>
                        </Grid>
                        <Grid item xs={6} sm={4} >
                            <Button style={{ backgroundColor: '#ff5722', color: '#FFFFFF' }} variant={'contained'}
                                id="add-btn"
                                aria-controls="add-menu"
                                aria-haspopup="true"
                                aria-expanded={openMenu ? 'true' : undefined}
                                onClick={(event) => onOpenMenu(event, IMG_TYPE.ID_ONE)}
                            >Id Picture</Button>
                        </Grid>
                        <Grid item xs={6} sm={8} >
                            <Paper style={{ overflow: "scroll" }}>{idImageName}</Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ paddingRight: '2vh' }}>
                    <Button style={{ backgroundColor: '#673ab7', color: '#FFFFFF' }} variant={'contained'} onClick={() => {
                        handleClose();
                        emptyField()
                    }}>Cancel</Button>
                    <Button style={{ backgroundColor: '#f50057', color: '#FFFFFF' }} variant={'contained'} onClick={() => {
                        onClickButton()
                        // setUserId("")
                    }}>Create A Player</Button>
                </DialogActions>

                <Menu
                    id="add-menu"
                    aria-labelledby="add-btn"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={onCapture}>Take Photo</MenuItem>
                    <MenuItem onClick={onUpload}>Upload</MenuItem>
                    {/* <MenuItem onClick={handleCloseMenu}>Cancel</MenuItem> */}
                </Menu>
            </Dialog>
            <CapturePhoto onActie={onApplyTakePic} handleClose={() => setTakePicOpen(false)} open={isTakePicOpen} />
            <PhotoUploadModal
                title={"Upload Profile Image"}
                isOpen={isUploadPicOpen}
                onApply={onApplyUploadPic}
                onToggle={() => {
                    setUploadPicOpen(false)
                }}
            />
        </>
    );
}

