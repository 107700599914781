import React from 'react';
import {Actions} from '../../initialize/modules/Actions';
import {ModuleEvents} from './Actions';
import {navigate} from '../../initialize/service/navigation';
import { routerMiddleware, push } from 'react-router-redux';

export default {
  [ModuleEvents.NETWORK_ERROR]: ({dispatch, payload, appState}) => {
   // Logger.infor(JSON.stringify(payload))
  
   },
   [ModuleEvents.CHANGE_ROUTE]: ({dispatch, payload, appState}) => {
    // Logger.infor(JSON.stringify(payload))
      navigate(payload)
    },
};
