import React, { useState, useEffect, useRef } from "react";

import { Modal, Box ,Grid, Button} from '@mui/material'
import "./photomodal.css";
import Remove from "./remove.png";
import Upload from "./upload.png";

export default ({ isOpen = false, onToggle = () => { }, onApply = () => { }, title = "Upload Profile Picture" }) => {
  const fileUploader = useRef(null);
  const [files, setFiles] = useState(null);
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);

  useEffect(() => {
    setImage(null)
    setName('')
    setFiles(null)
  }, [isOpen])

  const onUpload = () => {
    fileUploader.current.click()
  }

  const onChange = (e) => {
    const file = e.target.files[0];
    setFiles(file);
    setName(file.name);
    setImage(URL.createObjectURL(file))
  }

  const onRemove = () => {
    setFiles(null);
    setName("");
    setImage(null)
  }

  const apply = () => {
    onApply({ image, files, name });
  }

  const cancel = () => {
    onToggle()
  }

  const style = {
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    bgcolor: 'background.paper',
  };

  return (
    <div className={"row pho"}>
      <input accept="image/x-png,image/gif,image/jpeg" onChange={onChange} type="file" id="file" ref={fileUploader} style={{ display: "none" }} />
      <Modal

        open={isOpen}
        // onClose={onToggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={'imageBox'} sx={{ ...style}} >
          <div className={"photoHeader"}>
            <div>{title}</div>
          </div>
          <div className={"photoModalMiddleBody"}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6} >
                <div className={"photUploadBox"}>
                  {image ?
                    <div>
                      <img src={image} className={"uploadedImg"} />
                    </div>
                    : <div className={"boxInnexPh"}>
                      <div>
                        <img src={Upload} className={"photoUploadIcon"} />
                      </div>
                      <div>
                        <div onClick={onUpload} style={{ paddingLeft: "5px", paddingRight: "5px" }} className={"applyButton"}>Browse Files</div>
                      </div>
                    </div>}
                </div>
            
              </Grid>
              <Grid item sm={12} md={6} >
              <div className={"phoTopic"}>Uploading</div>
                {files && <div className={"uploadBox"}>
                  <div className={"uploadBoxInner"}>
                    <div className={"photoUploadName"}>{name}</div>
                  </div>
                  <div onClick={onRemove}>
                    <img className={"photoRemoveIcon"} src={Remove} />
                  </div>
                </div>}
              </Grid>
            </Grid>
            
          </div>
         <Grid spacing={2} container>
         <Grid className={'leftButton'} style={{display:"flex", alignItems:"center"}} item xs={6}>
         <Button className={"cancelButton"} variant="contained" onClick={cancel}>Cancel</Button>
         </Grid>
         <Grid className={'rightButton'} style={{display:"flex" ,alignItems:"center"}}  item xs={6}>
         <Button className={"applyButton"} variant="contained" onClick={apply}>Apply</Button>
         </Grid>
           
          
            {/* <div onClick={cancel} className={"cancelButton"}>Cancel</div>
            <div onClick={apply} className={"applyButton"}>Apply</div> */}
         </Grid>
        </Box>
      </Modal>
    </div>
  );
};

//toggle={onToggle} close={closeBtn}
