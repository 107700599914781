import { createAction } from '../../initialize/AppUitils';
import { PlayerRepository, CommonRepository } from '../../initialize/repository';


export const ModuleEvents = {
  GET_PLAYER_DATA: 'GET_PLAYER_DATA',
  UPDATE_LIST_STORAGE: 'UPDATE_LIST_STORAGE',
  GET_PLAYER_LIST: 'GET_PLAYER_LIST',
  CREATE_PLAYER: 'CREATE_PLAYER',
  GET_NEW_USER_DATA:'GET_NEW_USER_DATA',
  GET_ROUND_DATA:"GET_ROUND_DATA",
  GET_GRADE_DATA:"GET_GRADE_DATA",
  POST_MATCH_DATA:"POST_MATCH_DATA",
  GET_MATCH_DATA:"GET_MATCH_DATA",
  GET_DELEGATE_OF_CLUB:'GET_DELEGATE_OF_CLUB',
  GET_SQUAD_OF_CLUB:"GET_SQUAD_OF_CLUB",
  GET_FIXTURE:"GET_FIXTURE",
  GET_DELEGATE_FOR_CAPTIN:'GET_DELEGATE_FOR_CAPTIN',
  GET_ALL_CLUB:"GET_ALL_CLUB",
  MATCH_INFO: "MATCH_INFO",
  CONFIRM_BY_UMPIRE: "CONFIRM_BY_UMPIRE",
  
  GET_SUBMITED_MATCH_DATA : "GET_SUBMITED_MATCH_DATA",     // related to new API (umpire/submited_data)

};

export default {
  getPlayerData: createAction(ModuleEvents.GET_PLAYER_DATA, async id=>{
    return await PlayerRepository.getPlayerDetail(id);
  }),

  updateList: createAction(ModuleEvents.UPDATE_LIST_STORAGE, (list=[]) =>{
    localStorage.setItem('players',JSON.stringify(list));
    return list;
  }),

  getPlayerList: createAction(ModuleEvents.GET_PLAYER_LIST, async()=>{
    const data = await localStorage.getItem('players');
    return JSON.parse(data);
  }),

  createPlayer: createAction(ModuleEvents.CREATE_PLAYER, async (params)=>{
    return await PlayerRepository.createANewPlayer(params);
  }),
  
  getNewUserData:createAction(ModuleEvents.GET_NEW_USER_DATA, async id=>{
    return await PlayerRepository.getnewPlayerDetail(id);
  }),

  getRoundData: createAction(ModuleEvents.GET_ROUND_DATA, async ()=>{
    return await CommonRepository.getRounds();
  }),

  getGradeData: createAction(ModuleEvents.GET_GRADE_DATA, async ()=>{
    return await CommonRepository.getGrade();
  }),

  postMatchData: createAction(ModuleEvents.POST_MATCH_DATA, async(params)=>{
   return await CommonRepository.createTeamSheet(params)
  }),

  getMatchData: createAction(ModuleEvents.GET_MATCH_DATA, async(id)=>{
    const data= await CommonRepository.getClubFixtureMatch(id);
    localStorage.setItem('Match',JSON.stringify(data));
    return data;
   }),

   getDelegateList: createAction(ModuleEvents.GET_DELEGATE_OF_CLUB, async id =>{
     return await PlayerRepository.getDelgates(id);
   }),

   getSquadList: createAction(ModuleEvents.GET_SQUAD_OF_CLUB, async id =>{
    return await PlayerRepository.getSquad(id);
  }),

  getFixture: createAction(ModuleEvents.GET_FIXTURE, async id =>{
    return await CommonRepository.getFixtureData(id)
  }),

  getDelegateDetailForCaptain: createAction(ModuleEvents.GET_DELEGATE_FOR_CAPTIN, async id =>{
    const data = await PlayerRepository.getDelgatesForCID(id);//629519
    localStorage.setItem('DELEGATE',JSON.stringify(data));
    return data;
  }),

  getclubs: createAction(ModuleEvents.GET_ALL_CLUB, async () =>{
    return await CommonRepository.getClub();
  }),
  setMatchInfo: createAction(ModuleEvents.MATCH_INFO, data =>{
    return data;
  }),

  confirmUmpire: createAction(ModuleEvents.CONFIRM_BY_UMPIRE, data =>{
    return CommonRepository.confirmUmpire(data);
  }),

  // related to new API (umpire/submited_data)
  getMatchSubmitedData: createAction(ModuleEvents.CONFIRM_BY_UMPIRE, data =>{
    return CommonRepository.submited_data(data);
  }),
  
}
