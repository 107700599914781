import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Actions } from "../../../initialize/modules/Actions";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { CapturePhoto } from "../../../component";
import { Grid, Paper, Card, Avatar, CardActionArea, CardMedia, Typography, CardContent, Menu, MenuItem, Fab, IconButton, Badge, Button } from '@material-ui/core';
import { getImage } from "../../../initialize/service/navigation";
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
// import parseFormat from "moment-parseformat";

const ScoreScreen = ({ authData, allClubs, appinit, getFixture, fixtureList, setMatchInfo, delegatesList, getDelegateDetailForCaptain }) => {
    const history = useHistory();
    const [matches, setMatches] = useState([]);
    const [clubs, setClubs] = useState({});

    useEffect(() => {
        getFixture();
        getDelegateDetailForCaptain(authData.cricketid)
    }, []);


    useEffect(() => {
        const clubId = _.get(authData, 'clubid', '');
        // console.log("pppppp",delegatesList)
        const list = _(fixtureList).filter(item => (item.awayteam_club_id == clubId || item.hometeam_club_id == clubId )&& _.findIndex(delegatesList, d=>d.grade_id==item.grade_id )>-1).map(d => { return { ...d, date: new Date(moment(d.date_str, 'D MMM YY h:mmA')).getTime() } }).orderBy(['date', 'asc']).groupBy(i => moment(i.date).format('WW')).values().orderBy(i=>i[0].date).value();
        setMatches(list);
    }, [fixtureList, delegatesList]);

    useEffect(() => {
        const obj = _.groupBy(allClubs, 'id');
        setClubs(obj);
    }, [allClubs])

    useEffect(() => {
        if (!authData?.status) {
            appinit()
        }
        _.delay(() => {
            if (!authData?.status) {
                history.push('/login')
            }
        }, 100)
    }, [authData]);

    const onInfoScreen = (item) => {
        setMatchInfo(item);
        history.push('/info')
    }

    const renderItem = ({ item, index }) => {
        const { awayteam_club_id,
            date_str,
            grade_id,
            hometeam_club_id,
            id,
            remarrk,
            round_id,
            season_id,
            status,
            time,
            venue,
            name,
            venue_id } = item;
        const isConfirmed = status === 'CONFIRMED';

        // const format = parseFormat(date_str)
        const dateTime = moment(date_str, 'D MMM YY h:mmA').format('LLL')
        return (
            <Paper onClick={() => onInfoScreen(item)} className={isConfirmed ? 'cardOuterTM cardMatch cardMatchConfirmed InnerMatchCardBox' : 'cardOuterTM cardMatch InnerMatchCardBox'} >
                <Grid spacing={2} container>
                    <Grid item xs={12} >

                        <Typography style={{ textAlign: "center" }} className={'topoHeadText'} gutterBottom variant="h6" component="h2">
                            {`${_.get(clubs, `[${hometeam_club_id}][0].company`, '')}  VS  ${_.get(clubs, `[${awayteam_club_id}][0].company`, '')}`}
                        </Typography>

                        <Typography style={{ textAlign: "center" }} className={'classRoundColor'} variant="body2"  component="h6">
                        <h4>  {venue} Grade: {grade_id}  &nbsp; | &nbsp;  Round: {round_id}</h4>
                        </Typography>
                        <Typography style={{ textAlign: "center" }} variant="body2" color="textSecondary" component="p">
                            <h6>At  {dateTime}</h6>
                        </Typography>

                        <Typography style={{ textAlign: "center" }} variant="body2" color="textSecondary" component="p">
                            {name}
                            - ( {id} )
                        </Typography>
                    </Grid>

                </Grid>
            </Paper>
        )
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper style={{ paddingTop: '2%', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                        <h3>Match List - SCORE SCREEN - KDK TEST  </h3>
                    </Paper>
                </Grid>

                <Grid style={{ marginBottom: 110 }} item xs={12}>
                    {/* {matches.map((item, index) => {
                        return (
                            <div style={{ marginBottom: 10 }} key={`${index} matches`}>
                                {renderItem({ item, index })}
                            </div>
                        )
                    })} */}

                    {matches.map((d, i) => {
                        return (
                            <div  className={'matchCardBox'} key={`${i}obj`}>
                                {/* <Typography style={{ textAlign: "center" }} className={'topoHeadText'} gutterBottom variant="h6" component="h2">
                                    ({moment(d[0].date).format('YYYY-MMMM')} )  { "week number is ( "+moment(d[0].date).format('WW') +" )"}
                                </Typography> */}
                                {d.map((item, index) => {
                                    return (
                                        <div style={{ marginBottom: 10 }} key={`${index} matches`}>
                                            {renderItem({ item, index })}
                                        </div>
                                    )
                                })
                                }
                            </div>
                        )
                    })}
                </Grid>
            </Grid>
        </div>
    )
}


export default connect(
    state => ({
        fixtureList: state.home.get('fixtureList'),
        authData: state.auth.get("authData"),
        matchData: state.home.get('matchData'),
        allClubs: state.home.get('allClubs'),
        delegatesList: state.home.get('delegatesList'),
    }),
    {
        // getPlayerData: Actions.home.getPlayerData,
        getDelegateDetailForCaptain: Actions.home.getDelegateDetailForCaptain,
        setMatchInfo: Actions.home.setMatchInfo,
        appinit: Actions.auth.appinit,
        getFixture: Actions.home.getFixture,
        getMatchData: Actions.home.getMatchData,
    })(ScoreScreen)