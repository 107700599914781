import { Map } from 'immutable';
import _ from 'lodash';
import { ModuleEvents } from './Actions';
import { ModuleEvents as SigninEvent } from "../auth/Actions";

const InitialState = Map({
  playerList: [],
  gradeList: [],
  roundList: [],
  delegates: [],
  squad: [],
  matchData: [],
  fixtureList:[],
  delegatesList:[],
  allClubs:[],
  matchInfo:{},
});



export const Reducer = (state = InitialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ModuleEvents.GET_PLAYER_DATA: {
      if (payload && !payload.error) {
        const playerList = state.get('playerList');
        const isExist = _.findIndex(playerList, item => item.cid == payload.cid) > -1;
        if (!isExist) {
          const list = [payload, ...playerList];
          return state.set('playerList', list)
        }
      }
      return state
    }

    case ModuleEvents.UPDATE_LIST_STORAGE: {
      if (payload && payload.length && payload.length > 0) {
        return state.set('playerList', payload)
      }
      return state
    }

    case ModuleEvents.GET_NEW_USER_DATA: {
      if (payload && !payload.error) {
        const playerList = state.get('playerList');
        const list = [payload, ...playerList];
        return state.set('playerList', list)
      }
      return state
    }
    case ModuleEvents.GET_ROUND_DATA: {
      if (payload && !payload.error && _.isArray(payload)) {

        return state.set('roundList', payload)
      }
      return state
    }

    case ModuleEvents.GET_GRADE_DATA: {
      if (payload && !payload.error && _.isArray(payload)) {
        ;
        return state.set('gradeList', payload)
      }
      return state
    }

    case ModuleEvents.GET_FIXTURE: {
      if (payload && !payload.error && _.isArray(payload)) {
        return state.set('fixtureList', payload)
      }
      return state
    }

    case SigninEvent.SIGN_OUT: {
      return state.set('playerList', [])
    }

    case ModuleEvents.GET_DELEGATE_OF_CLUB: {
      return state.set('delegates', payload)
    }

    case ModuleEvents.GET_SQUAD_OF_CLUB: {
      return state.set('squad', payload)
    }

    case ModuleEvents.GET_MATCH_DATA: {
      if (payload && !payload.error && !payload.status){
        return state.set('matchData', payload)
      }else{
        return state.set('matchData', []);
      }
      
    }

    case ModuleEvents.GET_DELEGATE_FOR_CAPTIN: {
      return state.set('delegatesList', payload)
    }

    case ModuleEvents.GET_ALL_CLUB:{
      if(payload && !payload.error){
        return state.set('allClubs',payload)
      }
      return state
    }

    case ModuleEvents.MATCH_INFO:{
      return state.set('matchInfo',payload)
    }
  }


  return state;
};
