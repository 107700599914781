import React from 'react';
import {Actions} from '../../initialize/modules/Actions';
import {ModuleEvents} from './Actions';
import Swal from 'sweetalert2'
import _ from "lodash";
// import {navigate} from '../../initializer/repositories/Repository';

export default {
  [ModuleEvents.GET_PLAYER_DATA]: ({dispatch, payload, appState}) => {
   // Logger.infor(JSON.stringify(payload))
   if(payload && !payload.error){
    Swal.fire('Success', 'new player is added','success')  // - Remove by KDK 
   }else{
    Swal.fire('Oops...', 'Sorry, the user detail is not available', 'error')
   }
   },

   [ModuleEvents.GET_PLAYER_LIST]: ({dispatch, payload, appState})=> {
     if(payload && payload.length && payload.length>0){
       dispatch(Actions.home.updateList(payload));
     }
   },

    [ModuleEvents.CREATE_PLAYER]:({dispatch, payload, appState})=>{
      if(payload){
        dispatch(Actions.home.getNewUserData(payload))
      }
    },

    [ModuleEvents.POST_MATCH_DATA]:({dispatch, payload, appState})=>{
      if(payload){
        if(payload && !payload.error){
          Swal.fire('Success', 'Team sheet was submitted','success');
          const authData = appState.auth.get('authData');
          const playerList = appState.home.get('playerList');
          const newList = _.filter(playerList,item =>item.cricketid==authData.cricketid);
          dispatch(Actions.home.updateList(newList));
          localStorage.setItem('selectedRound',JSON.stringify(''))
          localStorage.setItem('selectedFixture',JSON.stringify(''))
          localStorage.setItem('selectedGrade',JSON.stringify(''))
          // dispatch(Actions.home.getMatchData(99999))
         }else{
          Swal.fire('Oops...', 'Sorry, Team sheet was failed to submit, please try again ', 'error')
         }
      }
    },

    [ModuleEvents.GET_DELEGATE_OF_CLUB]:({dispatch, payload, appState})=>{
      // if(payload){
      //   dispatch(Actions.home.getNewUserData(payload))
      // }
    },

    [ModuleEvents.CONFIRM_BY_UMPIRE]:({dispatch, payload, appState})=>{
      if(payload){
        if(payload == 'OK'){
          Swal.fire('Success', 'Match is confirmed successfully', 'Success')
          dispatch(Actions.home.getFixture(''))
        }else{
          Swal.fire('Oops...', 'Sorry, Submit was failed, Please try again', 'error')
        }
       
      }
    },
};
