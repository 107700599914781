import React, { useState, useEffect, useRef } from "react";
import QRCode from "qrcode";
import { Grid, Paper, Card,CardContent, Menu, MenuItem, Fab, IconButton } from '@material-ui/core';
//import { Grid, Paper, Card,CardContent, Menu, MenuItem, Fab, IconButton, Avatar, CardActionArea, CardMedia, Typography,  Badge, Button } from '@material-ui/core';
import QrReader from "react-qr-reader";
import { Delete as DeleteIcon } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from 'react-redux';
import { Actions } from "../../../initialize/modules/Actions";
import { useHistory } from "react-router-dom";
import Navigation from "../../common/view/Navigation";
import { getImage } from "../../../initialize/service/navigation";
import _ from "lodash";
import Swal from 'sweetalert2';
import AddIcon from '@mui/icons-material/Add';
import { InputCard, AddUserCard, QrScanner } from "../../../component";
import "../DashBoardStyle.css";
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    largeImage: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        // borderRadius:theme.spacing(7),
        // objectFit:""
    },
    space: {
        marginTop: theme.spacing(2),
        position: 'relative'
    },
    spaceMax: {
        marginTop: theme.spacing(2),
        MarginBottom: theme.spacing(100),
        position: 'relative'
    }
}));

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const DashBord = ({ getPlayerData, playerList, updateList, getPlayerList, authData, appinit, createPlayer, getclubs }) => {
    const [QRcode, setQRCode] = useState('');
    const QRRef = useRef();
    const [scanData, setScanData] = useState('');
    const [scanError, setScanError] = useState('');
    const [isScanError, setIsScanError] = useState(false);
    const [LIST, setLIST] = useState([]);
    const history = useHistory()
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [isManuallyAddInputCardOpen, setIsManuallyAddIInputCardOpen] = useState(false);
    const [isCraeteUserModalOpen, setIsCraeteUserModalOpen] = useState(false);
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const [isMobileSize, setMobileSize] = useState(false);
    const [isQrScannerOpen, setQrScannerOpen] = useState(false);
    const QRScannerRef = useRef();
    useEffect(() => {
        if (!authData?.status) {
            appinit()
        }
        _.delay(() => {
            if (!authData?.status) {
                history.push('/login')
            }
        }, 100)
        // _.delay(() => {
        //     if (!authData?.status) {
        //         history('/login')
        //     }
        // }, 4000)


    }, [authData])


    const generateQRCode = async () => {
        try {
            const data = await QRCode.toDataURL(new Date().getTime() + 'Hi');
            setQRCode(data)
        } catch (error) {

        }
    }

    useEffect(() => {
        getPlayerList();
        getclubs();
    }, [])

    useEffect(() => {
        // generateQRCode()
        setLIST(playerList);
        updateList(playerList)
    }, [playerList]);



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setMobileSize(false)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickFAB = (event) => {
        setAnchorEl2(event.currentTarget);
        setMobileSize(true)
    };
    const handleCloseFAB = () => {
        setAnchorEl2(null);
    };

    const onAddUserManually = () => {
        if (isMobileSize) {
            handleCloseFAB();
        } else {
            handleClose();
        }
        setIsManuallyAddIInputCardOpen(true)
    }

    const onCreateANewUser = () => {
        if (isMobileSize) {
            handleCloseFAB()
        } else {
            handleClose();
        }

        setIsCraeteUserModalOpen(true);
    }

    const onCloseManuallyAddInputCard = () => {
        setIsManuallyAddIInputCardOpen(false);
    }

    const onCloseCreateUserModal = () => {
        setIsCraeteUserModalOpen(false);
    }


    const onScan = (value, isDirectId) => {

        if (value) {
            const id = isDirectId ? value : value.split("=")[1];
            const index = _.findIndex(LIST, item => item.cid == id)

            if (index > -1) {
                const item = LIST[index];
                const { pimage, cid, email, first_name, last_name } = item;
                Swal.fire({
                    title: 'Oops',
                    text: `${first_name} ${last_name} is already available in list`,
                    imageUrl: getImage(pimage),
                    imageWidth: 200,
                    imageHeight: 200,
                    imageAlt: 'Custom image',
                    borderRadius: 200,
                    animation: true
                })
            } else {
                getPlayerData(id);

            }
            // setScanData(value)
            // setIsScanError(false)
        }

    };

    const onHanddleError = (error) => {
        setScanError(error);
        setIsScanError(true)
    }

    const onDragEnd = (params) => {
        const sourceIndex = params.source?.index;
        const destinationIndex = params.destination?.index;
        if (destinationIndex) {
            LIST.splice(destinationIndex, 0, LIST.splice(sourceIndex, 1)[0]);
            setLIST(LIST)
            updateList(LIST)
        }
    }

    const onAddNewUser = (item) => {
        // const data = { ...item, club_id: authData.id };
        onCloseCreateUserModal();
        createPlayer(item);
    }

    const onAddFromSquad = () => {
        history.push('/squad')
    }

    const onRemove = (data, indexNumber) => {
        const { first_name, last_name } = data;
        const isAvailable = _.has(data,'player_id');
        const id =isAvailable? _.get(data, 'player_id', _.get(data, 'id', 0)) : _.get(data, 'cricketid', _.get(data, 'cid', 0))

        const newList = isAvailable? _.filter(LIST, item => id != (item.player_id || item.id)) : _.filter(LIST, item => id != (item.cricketid || item.cid)) ;

        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to remove ${first_name} ${last_name} from your list ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                setLIST(newList)
                updateList(newList)
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        })

    };
    const handleQrScannerhide = () => {
        setQrScannerOpen(false);
    }

    const renderItem = ({ item, index }) => {
        const { pimage, cid, email, first_name, last_name, rank, middle_name, other_name, cricketid } = item;
        console.log("cricketid : "+cricketid)
        // if (cricketid === 'undefined') {
        //   cricketid = '+++';
        // }
        return (

            <Card className={LIST.length - 1 === index ? 'maxSpace ' + classes.spaceMax : classes.space}>
                {/* <div className={'count-box'}> <div >{index + 1}</div> </div> */}

                <CardContent className={'newDashUserCard'} style={{
                    paddingLeft: 0,
                    backgroundImage: `url(${getImage(pimage)})`,
                }}>
                    <Grid className={'nameCArdSelect'} container spacing={1}>
                        <IconButton onClick={() => onRemove(item, index)} aria-label="delete">
                            <DeleteIcon />
                        </IconButton><div style={{ width: 30 }} />

                    </Grid>
                    <Grid className={'nameCArd'} container spacing={1}>
                        <Grid item xs={12} >
                            <div className={'nameNewCardCidFont'}>{`${cricketid ? cricketid : cid }`}</div>
                            <div className={'nameNewCardNameFont'}>{`${first_name} ${last_name} ${middle_name} ${other_name}`}</div>
                            <div className={'nameNewCardRankFont'}> Player points: {rank}</div>

                        </Grid>
                    </Grid>


                </CardContent>

            </Card>

        )
    }


    return (
        <>
            {/* <Navigation /> */}
            <div style={{ padding: "20px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4} >
                        <div className={'qrBox'}>
                            {/* <Paper className={'qrBox'}> */}
                            <QrReader
                                delay={300}
                                className={'qrCodeStyle'}
                                onError={onHanddleError}
                                onScan={onScan}
                                ref={QRRef} />
                        </div>
                        <div className={"dashBoardFloat"}>
                            <Fab
                                id="demo-positioned-button"
                                aria-controls="demo-positioned-menu"
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                color="primary" aria-label="add">
                                <AddIcon />
                            </Fab>
                            <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem onClick={onCreateANewUser}>Add brand new player</MenuItem>
                                <MenuItem onClick={onAddUserManually}>Add any VSCA player</MenuItem>
                                <MenuItem onClick={onAddFromSquad}>Add from TEAM squad</MenuItem>
                            </Menu>
                        </div>
                    </Grid>


                    <Grid style={{ position: 'relative' }} item xs={12} sm={12} md={8} >
                        {/* <Grid container spacing={3}> */}
                        <div className={""}>
                            <DragDropContext {...{ onDragEnd }}>
                                <Paper >{scanData}</Paper>
                                <Droppable droppableId="droppable-1" type="PERSON">
                                    {(provided, snapshot) => (
                                        <Paper
                                            style={{ backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'white' }}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        // style={{ marginBottom: '100px' }}
                                        >
                                            <Grid container spacing={3} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                {
                                                    LIST.map((item, index) => {
                                                       // console.log(item);
                                                        return (
                                                            <Grid item key={`${index}`} xs={6} sm={4} md={6} lg={3} >
                                                                <Draggable draggableId={"draggable-" + index} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className={``} role="alert">
                                                                            {renderItem({ item, index })}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </Paper>
                                    )}
                                </Droppable>

                            </DragDropContext>
                        </div>
                        {/* </Grid> */}

                    </Grid>


                </Grid>
                <InputCard open={isManuallyAddInputCardOpen} handleClose={onCloseManuallyAddInputCard} addUserById={(id) => {
                    onScan(parseInt(id), true)
                    onCloseManuallyAddInputCard()
                }} />

                <AddUserCard open={isCraeteUserModalOpen} handleClose={onCloseCreateUserModal} onAddUser={onAddNewUser} />
                {/* <QrScanner onScan={(value, isDirectId)=>{
                    if(value && value?.length>0){
                     //  onScan(value, isDirectId);
                        handleQrScannerhide()
                    }
                }} onHanddleError={(error)=>{
                    onHanddleError(error);
                     handleQrScannerhide()
                }} QRRef={QRScannerRef} open={isQrScannerOpen} handleClose={handleQrScannerhide}/> */}
            </div>
        </>
    )
}

export default connect(
    state => ({
        playerList: state.home.get('playerList'),
        authData: state.auth.get("authData"),
    }),
    {
        getPlayerData: Actions.home.getPlayerData,
        updateList: Actions.home.updateList,
        getPlayerList: Actions.home.getPlayerList,
        appinit: Actions.auth.appinit,
        createPlayer: Actions.home.createPlayer,
        getclubs: Actions.home.getclubs,
    }
)(DashBord);