import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { Actions } from "../../../initialize/modules/Actions";
import { useHistory } from "react-router-dom";
import Navigation from "../../common/view/Navigation";
import { getImage } from "../../../initialize/service/navigation";
import _ from "lodash";
import Swal from 'sweetalert2';
import { Button, MenuItem, FormControl, Select, InputLabel, Grid, Menu, Paper, Card, CardContent, Avatar, Typography, Checkbox } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
const PLAYER_COUNT = 11;


const useStyles = makeStyles((theme) => ({
    largeImage: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        // borderRadius:theme.spacing(7),
        // objectFit:""
    },
    space: {
        marginTop: theme.spacing(2),
        position: 'relative'
    },
    spaceMax: {
        marginTop: theme.spacing(2),
        MarginBottom: theme.spacing(100),
        position: 'relative'
    }
}));

const Squad = ({ playerList, squad, authData, getSquadList, appinit, getPlayerList, updateList, getPlayerData }) => {
    const history = useHistory();
    const [list, setList] = useState([]);
    const classes = useStyles();
    useEffect(() => {
        if (!authData?.status) {
            appinit()
        }
        _.delay(() => {
            if (!authData?.status) {
                history.push('/login')
            }
        }, 100);
    }, [authData]);

    useEffect(() => {
        const data = {clubId:_.get(authData, 'clubid', ''),cid:_.get(authData, 'cricketid', '')};
        getSquadList(data)
    }, []);

    useEffect(() => {
        const list = _(squad).uniqBy('cricketid').map((d, i) => {
            const isSelected = _.findIndex(playerList, item => item.cricketid === d.cricketid) > -1;
            return { ...d, isSelected }
        }).value();
        setList(list);
      
    }, [playerList, squad]);

    const onPressCard = (item) => {
        const { pimage, cricketid, email, first_name, last_name, middle_name, isSelected } = item;

        if (!isSelected) {
            // add
            if (playerList.length < PLAYER_COUNT) {
                updateList([item, ...playerList])
            } else {
                Swal.fire('Oops...', 'Sorry, Maximum player count was already added, ', 'error')
            }
        } else {
            // remove
            const index = _.findIndex(playerList, d => cricketid === d.cricketid)
            const newList = playerList.slice(0, index).concat(playerList.slice(index + 1))

            updateList(newList);
        }
    }

    // const renderItem = ({ item, index }) => {
    //     const { pimage, cricketid, email, first_name, last_name, isSelected } = item;
    //     return (
    //         <Card onClick={() => onPressCard(item)} style={{ backgroundColor: isSelected ? '#245cd3' : '#ffffff' }}>
    //             <CardContent>
    //                 <Grid container spacing={2}>
    //                     <Grid xs={3} item>
    //                         <Avatar
    //                             alt={`${first_name} ${last_name}`}
    //                             src={getImage(pimage)}
    //                             sx={{ width: 24, height: 24 }}
    //                         /></Grid>
    //                     <Grid xs={9} item>
    //                         <Typography style={{ color: isSelected ? '#ffffff' : '#000000' }} variant="body2" color="textSecondary" component="p">{first_name} {last_name}</Typography>
    //                     </Grid>
    //                 </Grid>

    //             </CardContent>
    //         </Card>
    //     )
    // }


    const renderItem = ({ item, index }) => {
        const { pimage, cricketid, email, first_name, last_name, isSelected, rank, middle_name, other_name } = item;
     
        return (

            <Card 
                onClick={() => onPressCard(item)}
                className={list.length - 1 === index ? 'maxSpace ' + classes.spaceMax : classes.space}
            >
                <CardContent className={'newDashUserCard'} style={{
                    paddingLeft: 0,
                    backgroundImage: `url(${getImage(pimage)})`,
                }}>
                    <Grid className={'nameCArdSelect'} container spacing={1}>
                        <Checkbox checked={isSelected}  onClick={()=>{onPressCard(item)}} />
                    </Grid>
                    <Grid className={'nameCArd'} container spacing={1}>
                        <Grid item xs={12} >
                            <div className={'nameNewCardCidFont'}>{`${cricketid}`}</div>
                            {/* <div className={'nameNewCardNameFont'}>{`${first_name} ${last_name} ${middle_name}`}</div> */}
                            <div className={'nameNewCardNameFont'}>{`${first_name} ${last_name} ${middle_name} ${other_name}`}</div>
                            <div className={'nameNewCardRankFont'}> Player points: {rank}</div>

                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

        )
    }

    return (
        <div style={{ margin: '4%' }}>
            <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                </Grid> */}

                <Grid item xs={12}>
                    <h4>Squad List</h4>

                    <Grid container spacing={2}>
                        {/* {list.map((item, index) => {
                            return (
                                <Grid item xs={12} sm={12} md={6} lg={4} key={`${index}`}>
                                    {renderItem({ item, index })}
                                </Grid>
                            )
                        })

                        } */}

                        {list.map((item, index) => {
                            return (
                                <Grid item key={`${index}`} xs={6} sm={4} md={3} lg={2} key={`${index}`} >
                                    {renderItem({ item, index })}
                                </Grid>
                            )
                        })

                        }
                    </Grid>
                </Grid>
            </Grid>

        </div>
    )
};

export default connect(
    state => ({
        playerList: state.home.get('playerList'),
        squad: state.home.get('squad'),
        authData: state.auth.get("authData"),
    }),
    {
        getPlayerData: Actions.home.getPlayerData,
        updateList: Actions.home.updateList,
        getPlayerList: Actions.home.getPlayerList,
        appinit: Actions.auth.appinit,
        getSquadList: Actions.home.getSquadList,
    }
)(Squad);

