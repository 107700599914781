import { is } from 'immutable';
import React from 'react';
import { Actions } from '../../initialize/modules/Actions';
import { ModuleEvents } from './Actions';
import { navigate, goBack } from '../../initialize/service/navigation';
import { routerMiddleware, push } from 'react-router-redux';
import _ from "lodash";
import Swal from 'sweetalert2';

export default {
  [ModuleEvents.IS_AUTH]: ({ dispatch, payload, appState }) => {
    // Logger.infor(JSON.stringify(payload))
    if (payload) {
      dispatch(push('/'));
      
      //  navigate('/home');
    } else {
      dispatch(push('/login'));
      //  navigate('/login')
    }
  },

  [ModuleEvents.LOGIN_USER]: ({ dispatch, payload, appState }) => {
    // Logger.infor(JSON.stringify(payload))
    _.delay(() => {
      if (payload?.status) {
        dispatch(push('/'));
        dispatch(Actions.home.getNewUserData(payload.id));
         dispatch(Actions.home.getDelegateList(_.get(payload,'clubid','')));
         dispatch(Actions.home.getSquadList(_.get(payload,'clubid','')));
         dispatch(Actions.home.getDelegateDetailForCaptain(_.get(payload,'cricketid','')));

         localStorage.setItem('selectedRound',JSON.stringify(''))
         localStorage.setItem('selectedFixture',JSON.stringify(''))
         localStorage.setItem('selectedGrade',JSON.stringify(''))
        //Swal.fire('Success', 'Login successfully','success')
        // navigate('/home');
      } else {
        dispatch(push('/login'));
        Swal.fire('Oops...', 'Sorry, Login Failed', 'error');
        //  navigate('/login')
      }
    }, 1000)
  },

  [ModuleEvents.SIGN_OUT]: ({ dispatch, payload, appState }) => {

    dispatch(push('/login'))

  },

  [ModuleEvents.CHANGE_PASSWORD]: ({ dispatch, payload, appState }) => {
    if(payload === 0){
      Swal.fire('Oops...', 'Sorry, password was not changed', 'error');
    }else{
      Swal.fire('Notification', 'Successfully changed your password', 'success');
    }
    
  },
};
