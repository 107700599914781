import axios from "axios";
import _ from "lodash";
let _navigator;

const Config = {
  API_NAME: 'message_rooms',
}


export default class Repository {
  //url = "http://localhost:4000/api/v0.2";
  //url = "https://vsca.api.2tusker.net:5002/api/v0.2";
  url = "https://api.vsca.v24aug.2tusker.com:5002/api/v0.2";

  constructor() {
 
  }

  data = async (method, path, data = null, token = '') => {
    return axios({
      method: method,
      url: this.url + path,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      }
    })
      .then((response) => response)
      .catch((e) => {
        return e.response;
      });
  };


  getAxios = async (path) => {
    return axios.get(path);
  }

  getData = (path, data, token = '') => {
    return this.data("get", path, data, token);
  };

  postData = (path, data, token = '') => {
    return this.data("post", path, data, token);
  };

  putData = (path, data, token = '') => {
    return this.data("put", path, data, token);
  };

}




