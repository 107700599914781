import Repository from "./Repository";
import _ from "lodash";

class CommonRepository extends Repository {
    getRounds = async () => {
        try {
            const data = await this.getData('/round', {});
            return _.get(data,'data',[]);
        } catch (error) {
            return { error }
        }
    }

    getGrade = async () => {
        try {
            const data = await this.getData('/grade', {});
            return _.get(data,'data',[]);
        } catch (error) {
            return { error }
        }
    }

    createTeamSheet = async (params) =>{
        try{
           const data = await this.postData('/teamsheet',params);
           return _.get(data,'data',null);
        }catch(error){
            return {error}
        }
    }

    getClubMatch = async (id) => {
        try{
            const data = await this.getData(`/teamsheet/clubid/${id}`,{});
            return _.get(data,'data',[]);
         }catch(error){
             return {error}
         }
    }

    getClubFixtureMatch = async (id) => {
        try{
            const data = await this.getData(`/teamsheet/fixture/${id}`,{});
            return _.get(data,'data',[]);
         }catch(error){
             return {error}
         }
    }

    getFixtureData = async (id) => {
        try{
            const data = await this.getData(`/fixture`,{});
            return _.get(data,'data',[]);
         }catch(error){
             return {error}
         }
    }

    getClub = async () => {
        try{
            const data = await this.getData(`/club`,{});
            return _.get(data,'data',[]);
         }catch(error){
             return {error}
         }
    }

    confirmUmpire = async (params)=>{
        //alert(params)
        try{
            const data = await this.putData(`/umpire/confirm`,params);
            return _.get(data,'data',[]);
         }catch(error){
             return {error}
         }
    }

    // related to new API (umpire/submited_data)
    submited_data = async (params)=>{
        //alert(params)
        try{
            const data = await this.putData(`/umpire/submited_data`,params);
            return _.get(data,'data',[]);
         }catch(error){
             return {error}
         }
    }


    
}

export default new CommonRepository();