import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContentText, TextField, DialogActions , Button, DialogContent} from "@material-ui/core";


export default ({ open = false, handleClose = () => { }, addUserById = () => { } }) => {
    const [id,setUserId]=useState('');
    const handleChange = (event) => {
        setUserId(event.target.value);
      };
    return (
        <>
            <Dialog open={open} 
           // onClose={handleClose}
            >
                <DialogTitle>Add Player using Cricket ID </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Type your team member's Cricket ID.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="outlined-name"
                        // id="name"
                        label="Cricket ID"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={id}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions style={{paddingRight:'2vh'}}>
                    <Button style={{backgroundColor:'#673ab7', color:'#FFFFFF'}}  variant={'contained'} onClick={handleClose}>Cancel</Button>
                    <Button style={{backgroundColor:'#f50057', color:'#FFFFFF'}}  variant={'contained'} onClick={()=>{
                        addUserById(id)
                        setUserId("")
                    }}>Add Player</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}