// import React, { useEffect, useState, useRef } from "react";
// import { Modal, Button, Box, Grid } from '@mui/material'
// import "./capturePhoto.css";
// import CameraAltIcon from '@material-ui/icons/CameraAlt';
// import DeleteIcon from '@material-ui/icons//Delete';
// import _ from "lodash";
// import Camera from 'react-html5-camera-photo';
// import 'react-html5-camera-photo/build/css/index.css';
// const style = {
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     pt: 2,
//     px: 4,
//     pb: 3,
// };

// export default ({ open, onActie = () => { }, handleClose = () => { } }) => {
//     const videoRef = useRef(null);
//     const photoRef = useRef(null);
//     const [hasImage, setHasImage] = useState(false);
//     const [time, setTime] = useState(0)
//     const [isOpen, setOpen] = useState(false);

//     const onVideo = () => {

//         navigator.mediaDevices.getUserMedia({
//             video: {
//                 //    width:300,
//                 //    height:300
//             }
//         }).then(stream => {
//             let video = videoRef.current;
//             video.srcObject = stream;
//             video.play();
//         }).catch(error => {

//             if (time <= 10) {
//                 _.delay(() => {
//                     let count = time + 1;
//                     setTime(count);

//                     console.log("video stream error :", error);
//                     console.log(time, "count of error :", count)
//                 }, 5000)
//             }
//         })
//     };

//     const onCapture = () => {
//         setHasImage(true)
//         const height = 400;
//         const width = 400;
//         let video = videoRef.current;
//         let photo = photoRef.current;
//         photo.width = width;
//         photo.height = height;
//         let ctx = photo.getContext('2d');
//         ctx.drawImage(video, 0, 0, width, height);
//         setHasImage(true)
//     }

//     const onClear = () => {
//         let photo = photoRef.current;
//         let ctx = photo.getContext('2d');
//         ctx.clearRect(0, 0, 0, 0);
//         setHasImage(false);
//         onVideo();
//         setTime(0)
//     }

//     const onUpload = () => {
//         var canvas = document.getElementById('canvas');
//         var dataURL = canvas.toDataURL();
//         onActie(dataURL)
//         onClear();
//         // videoRef.current.pause();
//         setTime(10);
//     }

//     useEffect(() => {
//         setTime(0);
//         onVideo()
//     }, [open])
//     useEffect(() => {
//         onVideo()
//     }, [videoRef.current]);

//     function handleTakePhoto (dataUri) {
//         // Do stuff with the photo...
//         console.log('takePhoto');
//       }

//     return (
//         <Modal
//             hideBackdrop
//             open={open}
//             onClose={handleClose}
//             aria-labelledby="child-modal-title"
//             aria-describedby="child-modal-description"
//         >
//             <Box className={'imageBox'} sx={{ ...style, width: 'auto' }}>
//                 <Grid container spacing={2}>

//                     <Grid item style={{opacity:hasImage?1:0, position: hasImage?'relative':'absolute'}} item sm={12}>
//                             <canvas id="canvas" className={hasImage?'uploadedImg':''} ref={photoRef}></canvas>
//                             <div onClick={onClear} className={"camIcon"}>
//                                 <DeleteIcon />
//                             </div>
//                         </Grid> 
//                         {!hasImage &&
//                         <Grid item style={{position:"relative"}}>
//                             <video className={'uploadedImg'} ref={videoRef}></video>
//                             <div onClick={onCapture} className={"camIcon"}>
//                                 <CameraAltIcon />
//                             </div>
//                         </Grid>}

//                 </Grid>

//                 <Grid container spacing={2}>
//                     <Grid item xs={6}>
//                         <Button onClick={handleClose}>Close</Button>
//                     </Grid>
//                     <Grid item xs={6}>
//                         <Button onClick={onUpload}>Upload</Button>
//                     </Grid>

//                 </Grid>
//             </Box>
//         </Modal>
//     )
// }

import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Box, Grid, Paper } from '@mui/material'
import "./capturePhoto.css";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import DeleteIcon from '@material-ui/icons//Delete';
import _ from "lodash";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Swal from 'sweetalert2';

const style = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default ({ open, onActie = () => { }, handleClose = () => { } }) => {
    const videoRef = useRef(null);
    const photoRef = useRef(null);
    const [hasImage, setHasImage] = useState(false);
    const [time, setTime] = useState(0)
    const [isOpen, setOpen] = useState(false);
    const [image, setImage] = useState('');

    const handleTakePhoto = (dataUri) => {
        // Do stuff with the photo...
        //console.log('takePhoto');
    }

    const handleTakePhotoAnimationDone = (dataUri) => {
        // Do stuff with the photo...
        //console.log('takePhoto', dataUri);
        setImage(dataUri)
    }

    const handleCameraError = (error) => {
        //console.log('handleCameraError', error);
        Swal.fire({
            title: 'Oops',
            text: JSON.stringify(error),
            animation: true
        })
    }

    const handleCameraStart = (stream) => {
        //console.log('handleCameraStart');
    }

    const handleCameraStop = () => {
        //console.log('handleCameraStop');
    }

    return (
        <Modal
            hideBackdrop
            open={open}
            // onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className={'imageBox'} sx={{ ...style, width: 'auto' }}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Camera
                            onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                            onTakePhotoAnimationDone={(dataUri) => { handleTakePhotoAnimationDone(dataUri); }}
                            onCameraError={(error) => { handleCameraError(error); }}
                            idealFacingMode={FACING_MODES.ENVIRONMENT}
                            idealResolution={{ width: 640, height: 480 }}
                            imageType={IMAGE_TYPES.JPG}
                            imageCompression={0.97}
                            isMaxResolution={true}
                            isImageMirror={false}
                            isSilentMode={false}
                            isDisplayStartCameraError={true}
                            isFullscreen={false}
                            sizeFactor={1}
                            onCameraStart={(stream) => { handleCameraStart(stream); }}
                            onCameraStop={() => { handleCameraStop(); }}
                        />
                    </Grid>

                </Grid>
                {image.length > 0 && <Grid container spacing={2}>
                    <Grid style={{ display: 'flex', justifyContent: 'center' }} item xs={12}>
                        <img src={image} style={{ height: 100, width: 100 }} />
                    </Grid>
                </Grid>}
                <Grid container spacing={2}>
                    <Grid style={{ display: 'flex', justifyContent: 'flex-end' }} item xs={6}>
                        <Button style={{ backgroundColor: '#673ab7' }} variant={'contained'} onClick={()=>{
                            handleClose()
                            setImage('')
                            }}>Close</Button>
                    </Grid>
                    <Grid style={{ display: 'flex', justifyContent: 'flex-start' }} item xs={6}>
                        <Button style={{ backgroundColor: '#f50057' }} variant={'contained'} onClick={() => {
                            if (image.length > 0) {
                                onActie(image);
                                setImage('')
                            }
                        }}>Upload</Button>
                    </Grid>

                </Grid>
            </Box>
        </Modal>
    )
}