
import Reducers from './Reducers';
import actionMiddleware from '../middleware/ActionMiddleware';
import promiseMiddleware from '../middleware/PromiseMiddleware';
import React from 'react';
import { compose, createStore, combineReducers, applyMiddleware } from 'redux'
import { createBrowserHistory, createMemoryHistory} from 'history';

import logger from 'redux-logger';
import { ConnectedRouter, routerReducer, routerMiddleware, push } from 'react-router-redux';

const isServer = typeof window === 'undefined' || typeof document === 'undefined';

const appHistory = isServer ? createMemoryHistory() : createBrowserHistory({
  forceRefresh: false,      // Set true to force full page refreshes
   keyLength: 2,             // The length of location.key
});

// const reduxRouterMiddleware = syncHistory(appHistory);
const rootReducer = combineReducers(
  {
    ...Reducers,
  });

const routeMiddleware = routerMiddleware(appHistory)

const enhancer = compose(
  applyMiddleware(
    promiseMiddleware,
    logger,
    actionMiddleware(),
    //reduxRouterMiddleware,
    routeMiddleware,
  ));

const store = createStore(rootReducer, {}, enhancer);

//InitApp(store);

export { appHistory, store };
