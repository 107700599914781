import {createAction} from '../../initialize/AppUitils';


export const ModuleEvents = {
  LOADING_STARTED: 'LOADING_STARTED',
  LOADING_FINISHED: 'LOADING_FINISHED',
  NETWORK_ERROR:'NETWORK_ERROR',
  CHANGE_ROUTE:'CHANGE_ROUTE',
};

export default {
  networkError: createAction(ModuleEvents.NETWORK_ERROR, error => error),
  loadingStarted: createAction(ModuleEvents.LOADING_STARTED, action => action),
  loadingFinished: createAction(ModuleEvents.LOADING_FINISHED, action => action),
  changeRoute: createAction(ModuleEvents.CHANGE_ROUTE, path =>path),
};
