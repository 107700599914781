import Repository from "./Repository";
import _ from "lodash";

class PlayerRepository extends Repository {

  getPlayerDetail = async (id) => {
    try {
      const data = await this.getData(`/user/cid/${id}`, {});
      return _.get(data,'data[0]');
    } catch (error) {
      return { error }
    }
  }

  getnewPlayerDetail = async (id) => {
    try {
      const data = await this.getData(`/user/${id}`, {});
      return _.get(data,'data[0]');
    } catch (error) {
      return { error }
    }
  }

  Login = async (params) =>{
    try {
      const data = await this.postData(`/user/signin`, params);
      return _.get(data,'data');
    } catch (error) {
      return { error }
    }
  }

  createANewPlayer = async(params)=>{
    try {
      const data = await this.postData(`/user/signup`, params);
      return _.get(data,'data');
    } catch (error) {
      return { error }
    }
  }

  changePassword = async(params)=>{
    try {
      // const data = await this.postData(`/user/signup`, params);
      const data = await this.putData('/user', params);
      return _.get(data,'data',0);
    } catch (error) {
      return { error }
    }
  }

  getDelgates = async (id)=>{
    try {
      const data = await this.getData(`/delegates/clubid/${id}`, {});
      return _.get(data,'data',[]);
    } catch (error) {
      return { error }
    }
  }

  getSquad = async ({clubId,cid})=>{
    try {
      //alert("ss");
      //console.log("ppppppp",clubId, cid)
      // const data = await this.getData(`/squad/club/${id}`, {});
     //console.log(`/squad/club/${clubId}/cid/${cid}`)
      const data = await this.getData(`/squad/club/${clubId}/cid/${cid}`, {});
      //console.log("TEST -- ",data);
      return _.get(data,'data',[]);
    } catch (error) {
      return { error }
    }
  }

  getDelgatesForCID = async (id)=>{
    try {
      const data = await this.getData(`/delegates/cid/${id}`, {});
      return _.get(data,'data',[]);
    } catch (error) {
      return { error }
    }
  }

}

export default new PlayerRepository();