import React from 'react';
import { Nav, NavItem} from 'reactstrap';
// import { NavLink } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHome, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { BottomTab, TopNavBar } from "../../../component";

import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useHistory } from "react-router-dom";
// import { makeStyles } from '@material-ui/core/styles';
import {Avatar} from '@material-ui/core';
import {connect} from 'react-redux';
import {Actions} from "../../../initialize/modules/Actions";
import { useDispatch } from 'react-redux'


const Navigation = ({changeRoute}) => {
  const dispatch = useDispatch();
  const history = useHistory()
    const onChangePath = (path)=>{
        changeRoute(path)
        if(path === '/login'){
          dispatch(Actions.auth.signOut());
          history.push('/login')
        }
    }
  // rgb(43, 43, 43) 
  return (
    <div>
      <nav 
      style={{backgroundColor:"#245cd3"}} 
      className="navbar navbar-expand-md navbar-light  d-lg-block sticky-top" role="navigation">
       <TopNavBar {...{onChangePath}}/>
      </nav>
      <BottomTab />
    
    </div>
  )
};

export default connect(
    state => ({
      
    }),
    {
        changeRoute: Actions.common.changeRoute,
    }
  )(Navigation);

//  <Avatar alt="Travis Howard" src="https://material-ui.com/static/images/avatar/2.jpg" />
/*
 <nav  style={{backgroundColor:"#ccc"}} className="navbar fixed-bottom navbar-light d-block  bottom-tab-nav d-lg-block" role="navigation">
      <Nav className="w-100">
        <div className=" d-flex flex-row justify-content-around w-100">
          {
            tabs.map((tab, index) =>(
              <NavItem key={`tab-${index}`}>
                <NavLink to={tab.route} className="nav-link bottom-nav-link" activeClassName="active">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <FontAwesomeIcon size="sm" icon={tab.icon}/>
                    <div className="bottom-tab-label">{tab.label}</div>
                  </div>
                </NavLink>
              </NavItem>
            ))
          }
        </div>
      </Nav>
    </nav> 

*/

{/* <div className="container-fluid">
<a className="navbar-brand" href="/home">Brand</a>
<Nav className="ml-auto">
  <NavItem>
    <div onClick={()=>onChangePath('/home')} className="nav-link">
    <Avatar alt="Travis Howard" src="https://material-ui.com/static/images/avatar/2.jpg" />
    </div>
  </NavItem>
  <NavItem>
    <div onClick={()=>onChangePath('/login')} className="nav-link">
      Log Out
    </div>
  </NavItem>
</Nav>
</div> */}

// signOut: Actions.auth.signOut,