import {createAction} from '../../initialize/AppUitils';
import {PlayerRepository} from "../../initialize/repository";

export const ModuleEvents = {
  IS_AUTH: "IS_AUTH",
  LOGIN_USER:"LOGIN_USER",
  APP_INIT:"APP_INIT",
  SIGN_OUT:"SIGN_OUT",
  CHANGE_PASSWORD:'CHANGE_PASSWORD',
};

export default {
  isAuth: createAction(ModuleEvents.IS_AUTH, data => data),
  loginUser: createAction(ModuleEvents.LOGIN_USER, async params => {
      const data = await PlayerRepository.Login(params);
      if(data?.status){
        await localStorage.setItem('auth', JSON.stringify(data))
      }
      return data;
  },{loading:true, errorMessage:""}),
  appinit: createAction(ModuleEvents.APP_INIT, async()=>{
    const data = await localStorage.getItem("auth");
    return JSON.parse(data);
  }),

  signOut: createAction(ModuleEvents.SIGN_OUT, async ()=>{
      localStorage.clear();
      return null;
  }),

  changePassword: createAction(ModuleEvents.CHANGE_PASSWORD, async params=>{
    return await PlayerRepository.changePassword(params);
  })
};
