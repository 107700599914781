import React, { useEffect } from "react";
import {BottomNavigation,BottomNavigationAction} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import GroupIcon from '@material-ui/icons/Group';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {Nav} from "reactstrap";
import  "../bottomStyle.css";
import {SvgIcon,colors} from "@mui/material";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const useStyles = makeStyles({
    root: {
      width: 500,
      color:'white',
    },
  });

export default ({tabs}) => {
    const history = useHistory()
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    useEffect(()=>{
   _.delay(()=>{
    const path = _.get(history,'location.pathname','Player');
     setValue(path=='/match'?2:path=='/detail'?1:0);
    // setValue(path=='/info'?3:path=='/detail'?2:path=='/squad'?1 :0);
   },10000)
    },[history])
  
    return (
        <nav className="bottom-line navbar fixed-bottom navbar-light d-block  bottom-tab-nav d-lg-block" role="navigation">
            <Nav className="w-100">
        <div className="d-flex flex-row justify-content-around w-100">
            <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                 history.push(newValue==0?'/':newValue==1?'/detail':newValue==2?'/match':'/score')
                // history.push(newValue==0?'/':newValue==1?'/squad':newValue==2?'/detail':'/info')
            }}
            showLabels
            className={classes.root}
        >
            <BottomNavigationAction  label="Select Team" icon={<AccountCircleIcon />} />
            {/* <BottomNavigationAction label="Squad" icon={<GroupIcon/>} /> */}
            <BottomNavigationAction label="My Team" icon={<PlaylistAddCheckIcon/>} />
            <BottomNavigationAction label="Matches" icon={<SettingsOutlinedIcon/>} />
            
        </BottomNavigation>
        </div>
        </Nav>
        </nav>
    )
}

