import {Map} from 'immutable';
import _ from 'lodash';
import {ModuleEvents} from './Actions';
//import { ModuleEvents as SigninEvent } from "../signin/Actions";

const InitialState = Map({
  authData:{}
});



export const Reducer = (state = InitialState, action) => {
  const {payload, type} = action;
  switch (type) {
    case ModuleEvents.IS_AUTH: {
      return state;
    }

    case ModuleEvents.LOGIN_USER:{
      if(payload?.status){
        return state.set('authData', payload);
      }
      return state;
    }

    case ModuleEvents.APP_INIT:{
      if(payload?.status){
        return state.set('authData', payload);
      }
      return state;
    }

    case ModuleEvents.SIGN_OUT:{
      return state.set('authData', {});
    }
   
  }
  

  return state;
};
