import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { shallowEqual, useSelector } from 'react-redux'
// import { Router, Route, Switch } from "react-router";
import { useHistory } from "react-router-dom";
//import { BrowserRouter, Router, Switch, Route, useHistory , Link} from "react-router-dom";
import Navigation from "./module/common/view/Navigation";
import { store, appHistory } from "./initialize/modules/store";
//import { connect } from 'react-redux';
import { Actions } from "./initialize/modules/Actions";


const App = ({ isAuth }) => {
const history=useHistory()
const authData = useSelector((state) => state.auth.get('authData'));


    useEffect(() => {
      // _.delay(()=> store.dispatch(Actions.auth.isAuth(true)),2000)
    }, [])

    return (
        <>
        { authData?.status && <Navigation/>}
        
        </>
    )

}

export default App;

store.dispatch(Actions.auth.appinit());