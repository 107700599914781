import { appHistory } from "../modules/store";
import { createBrowserHistory } from 'history';
import {useHistory} from "react-router-dom";

export const navigate =  async(pathname) => {
    appHistory.push(pathname);
}

export const goBack = ()=>{
    appHistory.goBack()
}

export const getImage = (imageName)=>{
    return `https://idcard.vsca.com.au/upload/profile/${imageName}`
}