/*

- 2022 july 22 new updates by Kanchana 
- Change Squad Screen Changes 
- Change application version - v0.3  - OK 
- Home Screen changes 
- loading Spinner icon change 
 
    https://colorhunt.co/palette/06283d1363df47b5ffdff6ff

*/

// - fix error 
// export NODE_OPTIONS=--openssl-legacy-provider


import React, {useState, useEffect} from "react";
import ReactDom from "react-dom";
import App from "./App";
import { Provider } from 'react-redux';
import { store, appHistory } from "./initialize/modules/store";
import { BrowserRouter as Router, Switch, Route, useHistory, Link } from "react-router-dom";
import DashBoard from "./module/home/view/DashBoard";
import Login from "./module/auth/view/Login";
import OtherScreen from "./module/home/view/OtherScreen";
import InfoScreen from "./module/home/view/InfoScreen";
import SquadScreen from "./module/home/view/Squad";
import MatchScreen from "./module/home/view/MatchScreen";

import ScoreScreen from "./module/home/view/ScoreScreen";

import _ from "lodash";
//import ClimbingBoxLoader from "react-spinners/CircleLoader";
import ClimbingBoxLoader from "react-spinners/ClockLoader";

import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
`;


const InitApp = () => {
    const [loading, setLoading]=useState(false);
    const { innerWidth: width, innerHeight: height } = window;
    useEffect(()=>{
        setLoading(true)
        _.delay(() => {
           setLoading(false)
        }, 600)
        
    },[])
    return (
        <Provider store={store}>
            <div className={'initLoader'} style={{ display: loading ? 'flex' : 'none' , height, justifyContent:"center",alignItems:'center'}}>
                 {/* <h1>VSCA --</h1> <br></br> */}
                <ClimbingBoxLoader color={'#084dff'} loading={loading} css={override} size={100} speedMultiplier={1} />
            </div>
            <div style={{ opacity: loading ? 0 : 1, display: !loading ? 'block' : 'none'  }}>
                <Router history={appHistory}>
                    <App />
                    <Switch>
                    <Route exact path="/login" render={props => <Login {...props} />} />
                    <Route exact path="/" render={props => <DashBoard {...props} />} />
                    <Route exact path="/detail" render={props => <OtherScreen {...props} />} />
                    <Route exact path="/match" render={props => <MatchScreen {...props} />} />
                    <Route exact path="/squad" render={props => <SquadScreen {...props} />} />
                    <Route exact path="/info" render={props => <InfoScreen {...props} />} />

                    {/* New tab  */}
                    {/* <Route exact path="/score" render={props => <ScoreScreen {...props} />} />  */}

                    </Switch>
                </Router>
            </div>
        </Provider>
    )
}

ReactDom.render(
    <InitApp />
    , document.getElementById('root')
);

