import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Actions } from "../../../initialize/modules/Actions";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { CapturePhoto } from "../../../component";
import { Grid, Paper, Card, Avatar, CardActionArea, CardMedia, Typography, CardContent,
     Menu, MenuItem, Fab, IconButton, Badge, Button, TextField } from '@material-ui/core';
import { getImage } from "../../../initialize/service/navigation";
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import { PlayerRepository, CommonRepository } from '../../../initialize/repository';


const useStyles = makeStyles((theme) => ({

    largeImage: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        // borderRadius:theme.spacing(7),
        // objectFit:""
    },
    space: {
        marginTop: theme.spacing(2),
        position: 'relative'
    },
    spaceMax: {
        marginTop: theme.spacing(2),
        MarginBottom: theme.spacing(100),
        position: 'relative'
    }
}));
function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const InfoScreen = ({ authData, appinit, getMatchData, matchData, allClubs, matchInfo, confirmUmpire, fixtureList, getFixture, getMatchSubmitedData }) => {
    const history = useHistory();
    const [teams, setTeams] = useState({})
    const classes = useStyles();
    const [club, setClub] = useState('')
    const [opclub, setOpClub] = useState('')
    const [clubs, setClubs] = useState({});
    const [isConfirm, setIsConfirm] = useState(true)
    const [code, setCode] = useState('');
    const [clubsName, setClubsName] = useState({ home: '', away: '' })
    const [marks, setMarks] = useState({ home: 0, away: 0 });
    const [isCodeValid, setCodeValid]=useState(false);
    const [isCodeValidOk, setCodeValidOk]=useState(true);

    // KDK 
    const [sumbitDataObj, setSumbitDataObj] = useState({fid:null,clubid:'',user_id:''})
  
    useEffect(() => {
        getFixture();
    }, [])
    useEffect(() => {
        if (!authData?.status) {
            appinit()
        }
        _.delay(() => {
            if (!authData?.status) {
                history.push('/login')
            }
        }, 100)
    }, [authData]);

    useEffect(() => {
        //alert('useEffect 1')
        console.log(matchInfo,'fixtureList',fixtureList)
        const item = _.find(fixtureList, item => item.id == _.get(matchInfo, 'id', ''));
        console.log("item NEW ",item);
        const status = _.get(item, 'status', 'CONFIRMED')
        //setIsConfirm(status === 'CONFIRMED')
        if (item.id && authData.id) {
            isMatchConfirmd({fid:item.id,clubid:authData.clubid,user_id:authData.id})
            setSumbitDataObj({fid:item.id,clubid:authData.clubid,user_id:authData.id})
        }

    }, [fixtureList])

    const isMatchConfirmd = async (data) => {
        console.log("data NEW : ",data);
        const  umSubmit = await CommonRepository.submited_data(data);
        if (umSubmit.status != 404) {
            setIsConfirm(true)
        }else{
            setIsConfirm(false)
        }
        console.log("umSubmit NEW : ",umSubmit);

    } 

    useEffect(() => {
        getMatchData(_.get(matchInfo, 'id', ''));
    }, [matchInfo]);

    useEffect(() => {

        const dataList = _(matchData).uniqBy('cricketid').groupBy('club_id').value();
        setTeams(dataList);
        setClub(_.get(authData, 'clubid', ''))
        const oppKey = _(dataList).keys().filter(i => i != _.get(authData, 'clubid', '')).value();

        setOpClub(oppKey);

        const away = _.get(authData, 'clubid', '') == matchInfo.hometeam_club_id ? matchInfo.awayteam_club_id : matchInfo.hometeam_club_id;
        const home = _.get(authData, 'clubid', '');
        setClubsName({
            away,//:_.get(authData, 'clubid', '') == matchInfo.hometeam_club_id ? matchInfo.awayteam_club_id: matchInfo.hometeam_club_id,
            home,//:_.get(authData, 'clubid', '')
        });

        const homeCount = _.reduce(_.get(dataList, `[${home}]`, []), function (sum, n) {
           const point = _.isNaN(parseInt(_.get(n, 'rank', 0)))?0:parseInt(_.get(n, 'rank', 0));
            return sum + point;
        }, 0);

        const awayCount = _.reduce(_.get(dataList, `[${away}]`, []), function (sum, n) {
            const point = _.isNaN(parseInt(_.get(n, 'rank', 0)))?0:parseInt(_.get(n, 'rank', 0));
            return sum + point;
        }, 0);

        setMarks({home:homeCount, away: awayCount})
        console.log("matchData NEW ---",matchData)
    }, [matchData]);
    

    useEffect(() => {
        const obj = _.groupBy(allClubs, 'id');
        setClubs(obj);
    }, [allClubs])

    const changeCode = (event) => {
        const text = event.target.value;
        const isValid = text.trim().length > 3;
        setCode(text);
        setCodeValid(isValid);
        setCodeValidOk(true);
    };

    const renderItem = ({ item, index, LIST }) => {
        const { pimage, cid, email, first_name, last_name, rank, middle_name, other_name,cricketid } = item;

        return (

            <Card className={classes.space}>
                <CardContent className={'newDashUserCard'} style={{
                    paddingLeft: 0,
                    backgroundImage: `url(${getImage(pimage)})`,
                }}>

                    <Grid className={'nameCArd'} container spacing={1}>
                        <Grid item xs={12} >
                            <div className={'nameNewCardCidFont'}>{`${cricketid}`}</div>
                            <div className={'nameNewCardNameFont'}>{`${first_name} ${last_name} ${middle_name} ${other_name}`}</div>
                            <div className={'nameNewCardRankFont'}> Player points: {rank}</div>

                        </Grid>
                    </Grid>


                </CardContent>

            </Card>

        )
    }


    const onClickButton = () => {

        if (code == "") {
            Swal.fire('Incorrect', 'Sorry, Incorrect Code', 'error');
        }
        if (code && code.length && code.length > 2) {
            console.log(authData);
            confirmUmpire({ code, fid: _.get(matchInfo, 'id', ''),clubid: authData.clubid,user_id:authData.id }); 
        }else{
            setCodeValidOk(false)
        }
    }

    return (
        <div>
            <Grid style={{ marginBottom: 10 }} spacing={2} container>
                <Grid item xs={6} sm={6}>
                    <Paper className={'lableCardTM'}>
                        {_.get(clubs, `[${clubsName.home}][0].company`)}
                        - (Total {marks['home']})
                        </Paper>
                    <Grid container spacing={2}>
                        {_.get(teams, `[${club}]`, []).map((item, index) => {
                            return (
                                <Grid item key={`0${index}`} xs={12} sm={6} md={6} lg={3} >
                                    <div >
                                        {renderItem({ item, index, LIST: _.get(teams, `[${club}]`, []) })}
                                    </div>
                                </Grid>
                            )
                        })

                        }
                    </Grid>

                    <Grid style={{ marginBottom: 150, justifyContent: "center", alignItems: "center", display: 'flex' }} spacing={0} container >
                    {!isConfirm && matchData.length>= 7 && <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} xs={10} sm={6} md={4} lg={2}>
                        <TextField
                        autoFocus
                        margin="dense"
                        id="outlined-name"
                            error={!isCodeValidOk}
                        // id="name"
                        label="Umpire Code"
                        type="text"
                        fullWidth
                        placeholder={'Entrer Umpire Code'}
                            helperText={isCodeValidOk ? "" : "Please add valid code"}
                        value={code}
                        onChange={changeCode}
                        />

                        <Button style={{ backgroundColor: '#f50057', color: '#FFFFFF' }} variant={'contained'} onClick={() => {
                        onClickButton()
                        // setUserId("")
                        }}>Submit</Button>
                        </Grid>}

                    </Grid>
                    
                    {_.get(teams, `[${club}]`, []).length == 0 &&
                        <div>
                            <Paper className={'cardOuterTM'}>
                                <Typography className={'topoHeadText'} gutterBottom variant="h5" component="h2">
                                    List is not available
                                </Typography>
                            </Paper>
                        </div>
                    }
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Paper className={'lableCardTM'}>
                        {_.get(clubs, `[${clubsName.away}][0].company`)}
                      - (Total {marks['away']})
                        </Paper>
                    <Grid container spacing={2}>
                        {_.get(teams, `[${opclub}]`, []).map((item, index) => {
                            return (
                                <Grid item key={`${index}`} xs={12} sm={6} md={6} lg={3} >
                                    <div >
                                        {renderItem({ item, index, LIST: _.get(teams, `[${opclub}]`, []) })}
                                    </div>
                                </Grid>
                            )
                        })
                        }
                    </Grid>
                    

                    {_.get(teams, `[${opclub}]`, []).length == 0 &&
                        <div>
                            <Paper className={'cardOuterTM'}>
                                <Typography className={'topoHeadText'} gutterBottom variant="h5" component="h2">
                                    List is not available yet.
                                </Typography>
                            </Paper>
                        </div>
                    }
                </Grid>
            </Grid>

        </div>
    )
};

export default connect(
    state => ({
        playerList: state.home.get('playerList'),
        authData: state.auth.get("authData"),
        matchData: state.home.get('matchData'),
        allClubs: state.home.get('allClubs'),
        matchInfo: state.home.get('matchInfo'),
        fixtureList: state.home.get('fixtureList'),
    }),
    {
        getPlayerData: Actions.home.getPlayerData,
        updateList: Actions.home.updateList,
        getPlayerList: Actions.home.getPlayerList,
        appinit: Actions.auth.appinit,
        createPlayer: Actions.home.createPlayer,
        getMatchData: Actions.home.getMatchData,
        confirmUmpire: Actions.home.confirmUmpire,
        getFixture: Actions.home.getFixture,
        getMatchSubmitedData : Actions.home.getMatchSubmitedData,
    })(InfoScreen)