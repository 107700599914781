import _ from "lodash"
import { ModuleSet } from './ModuleSet';

const handlerModule = {
  common: require(`../../module/common/Handler`),
  home:require(`../../module/home/Handler`),
  auth:require(`../../module/auth/Handler`),
};

// export const Handlers = _(ModuleSet)
//   .keyBy((module) => module)
//   .mapValues((module) => {
//     try {
//       // eslint-disable-next-line global-require
//       const moduleHandler = handlerModule[module];
//       return moduleHandler.default;
//     } catch (e) {
//       // console.warn('ok', e);
//       return {default: {}};
//     }
//   })
//   .value();

export const Handlers = _(ModuleSet)
  .keyBy(module => module)
  .mapValues((module) => {
    try {
      // eslint-disable-next-line global-require
      console.log(module);
      const moduleHandler = require(`../../module/${module}/Handler`);
      return moduleHandler.default;
    } catch (e) {
       console.warn(e);
      return { default: {} };
    }
  })
  .value();
  
const EventHandlers = new Map();
export const AllHandlers = _(Handlers)
  .transform((result, handler) => {
    _.mapKeys(handler, (fn, key) => {
      if (key === 'default') {
        return;
      }
      result[key] = result[key] ? _.concat(result[key], fn) : [fn];
    });
    return result;
  }, {})
  .value();
export default Handlers;
